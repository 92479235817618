import { createSlice } from "@reduxjs/toolkit";
import Config from "../../../Config/TemplateConfig";

const initialState = {
  messengerActiveTab: "chat",
  subChatActiveTab: "direct",
  mobileMenu: true,
  toggleSmallSide: true,
  activeSection: null,
  videoCall: false,
  audioCall: false,
  profileToggle: false,
  sidebarToggle: true,
  profileSidebarWidth: 0,
};

const MessengerSlice = createSlice({
  name: "MessengerSlice",
  initialState,
  reducers: {
    setMessengerActiveTab: (state, action) => {
      state.messengerActiveTab = action.payload;
    },
    setSubChatActiveTab: (state, action) => {
      state.subChatActiveTab = action.payload;
    },
    toggleVisibility: (state, action) => {
      state.activeSection = state.activeSection === action.payload ? null : action.payload;
    },
    setMobileMenu: (state) => {
      if (state.mobileMenu) {
        document.querySelector(".sidebar-toggle")?.classList.add("mobile-menu");
        console.log('enabled')
      }
      else {
        document.querySelector(".sidebar-toggle")?.classList.remove("mobile-menu");
        // scroll to top
        window.scrollTo(0, 0);
      }

      state.mobileMenu = !state.mobileMenu;
    },
    setVideoCall: (state) => {
      state.videoCall = !state.videoCall;
    },
    setAudioCall: (state) => {
      state.audioCall = !state.audioCall;
    },
    profileSideBarToggle: (state) => {
      if (state.profileToggle) {
        document.body.classList.remove("menu-active");
        document.querySelector(".chatzy-main")?.classList.add("small-sidebar");
      } else {
        document.body.classList.add("menu-active");
        document.querySelector(".chatzy-main")?.classList.remove("small-sidebar");
      }
      if (state.profileSidebarWidth <= 1440) {
        if (!state.profileToggle) document.querySelector(".sidebar-toggle")?.classList.add("mobile-menu", "sidebar-overlap");
        else document.querySelector(".sidebar-toggle")?.classList.remove("mobile-menu", "sidebar-overlap");
      }
      state.profileToggle = !state.profileToggle;
    },
    OpenCloseSidebar: (state, action) => {
      const CloseSidebar = state.profileSidebarWidth <= 800 ? !action.payload : action.payload;
      if (CloseSidebar) document.querySelector(".main-nav")?.classList.add("on");
      else document.querySelector(".main-nav")?.classList.remove("on");
      state.sidebarToggle = !action.payload;
      Config.sidebar_setting = action.payload ? "no" : "";
    },
    setProfileSidebarWidth: (state, action) => {
      state.profileSidebarWidth = action.payload;
    },
  },
});

export const { setMessengerActiveTab, setSubChatActiveTab, toggleVisibility, setMobileMenu, setVideoCall, setAudioCall, profileSideBarToggle, OpenCloseSidebar, setProfileSidebarWidth } = MessengerSlice.actions;
export default MessengerSlice.reducer;
