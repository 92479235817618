import { MessageSquare, Phone, Users } from "react-feather";

//App Sidebar
export const AppListData = [
];

export const FileTabListData = ["Media", "Link", "Docs"];

export const MediaSectionsData = [
 
];

export const ToDoAllData = [
];

export const ToDoDropdownData = [];

export const ReminderModalData = [
];

//Main Sidebar
export const MainSidebarListData = [
  { class: "step1", type: "status", icon: "messege" },
  { type: "contact-list", icon: "userbox" },
  { type: "notification", icon: "notification" },
  { type: "document", icon: "document" },
  { type: "favorite", icon: "favourite" },
  { class: "step2", type: "settings", icon: "setting" },
];

export const ContactStatusData = [
];

export const ContactData = [
];

export const NotificationData = [
];

export const FavoriteData = [
];

export const PrivacyData = [
];


export const RecentData = [
];

export const ChatFriendContent = [
  { class: "stroke-dark", icon: "clear", title: "Clear dark" },
  { class: "fill-dark", icon: "block", title: "Block" },
];

export const ContactInformationData = [
];

export const ContactFormData = [
  { title: "Block", check: false },
  { title: "Mute", check: false },
  { title: "Get Notification", check: true },
];

export const OtherContactData = [
];

export const ColorPickerData = [
  { class: "color1", color: "style1", active: true },
  { class: "color2", color: "style2", active: false },
  { class: "color3", color: "style3", active: false },
  { class: "color4", color: "style4", active: false },
  { class: "color5", color: "style5", active: false },
];

export const TemplateLayoutData = [
  { active: true, layoutValue: "" },
  { active: false, layoutValue: "dark-sidebar" },
  { active: false, layoutValue: "dark" },
  { active: false, layoutValue: "colorfull" },
];

export const ChatWallpaperData = [
  { id: 1, active: false, class: "bg-color", wallpaperClass: "bg-default", wallpaperCss: "-webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(1, 170, 133, 0.1)))" },
  { id: 2, active: false, class: "bg-size", wallpaperImage: "2" },
  { id: 3, active: false, class: "bg-size", wallpaperImage: "3" },
  { id: 4, active: false, class: "bg-size", wallpaperImage: "4" },
  { id: 5, active: false, class: "bg-size", wallpaperImage: "5" },
  { id: 6, active: true, class: "bg-size", wallpaperImage: "1" },
  /*
  { id: 7, active: false, class: "bg-color", wallpaperClass: "grediant-1", wallpaperCss: "linear-gradient(359.3deg, rgba(1, 170, 133, 0.1) 1%, rgba(187, 187, 187, 0) 70.9%)" },
  { id: 8, active: false, class: "bg-color", wallpaperClass: "grediant-2", wallpaperCss: "radial-gradient(328px at 2.9% 15%, rgb(191, 224, 251) 0%, rgb(232, 233, 251) 25.8%, rgb(252, 239, 250) 50.8%, rgb(234, 251, 251) 77.6%, rgb(240, 251, 244) 100.7%)" },
  { id: 9, active: false, class: "bg-color", wallpaperClass: "grediant-3", wallpaperCss: "linear-gradient(109.6deg, rgb(223, 234, 247) 11.2%, rgb(244, 248, 252) 91.1%)" },
  { id: 10, active: false, class: "bg-color", wallpaperClass: "grediant-4", wallpaperCss: "linear-gradient(-109.6deg, rgb(204, 228, 247) 11.2%, rgb(237, 246, 250) 100.2%)" },
  { id: 11, active: false, class: "bg-color", wallpaperClass: "grediant-5", wallpaperCss: "radial-gradient(circle at 10% 20%, rgb(239, 246, 249) 0%, rgb(206, 239, 253) 90%)" },
  { id: 12, active: false, class: "bg-color", wallpaperClass: "grediant-6", wallpaperCss: "radial-gradient(circle at 10% 20%, rgb(226, 240, 254) 0%, rgb(255, 247, 228) 90%)" },
  */
];
