import { createSlice } from "@reduxjs/toolkit";
import { ContactStatusData, FavoriteData, NotificationData } from "../../../Data/Messenger/Messenger";

const initialState = {
  mainSidebarActiveTab: "",
  mainSidebarWidth: 0,
  contactStatusActive: ContactStatusData,
  notificationActive: NotificationData,
  favoriteActive: Array(FavoriteData.length).fill(false),
  selected: "1",
  account: "1",
  settingsActiveTab: "",
};

const MainSidebarSlice = createSlice({
  name: "MainSidebarSlice",
  initialState,
  reducers: {
    setMainSidebarActiveTab: (state, action) => {
      state.mainSidebarActiveTab = action.payload;
      document.querySelector(".recent-default")?.classList.remove("active");
    },
    setMainSidebarWidth: (state, action) => {
      state.mainSidebarWidth = action.payload;
    },
    closeLeftSide: (state) => {
      document.querySelector(".recent-default")?.classList.add("active");
      state.mainSidebarActiveTab = "";
    },
    setNotificationActive: (state, action) => {
      state.notificationActive = state.notificationActive.filter((_, i) => i !== action.payload);
    },
    setFavoriteActive: (state, action) => {
      state.favoriteActive = state.favoriteActive.map((item, i) => (i === action.payload ? !item : item));
    },
    setSettingsActiveTab: (state, action) => {
      state.settingsActiveTab = action.payload;
    },
    setAccount: (state, action) => {
      state.account = action.payload;
    },
  },
});

export const { setMainSidebarActiveTab, setMainSidebarWidth, closeLeftSide, setNotificationActive, setFavoriteActive, setSettingsActiveTab, setAccount } = MainSidebarSlice.actions;
export default MainSidebarSlice.reducer;
