import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthRoutes } from "./AuthPaths";
import RedirectRoute from "./RedirectRoute";
import NavigateTo from "./navigate-to";
import FullPageLoader from "../Components/full-page-loader";
const Dashboard = lazy(() => import("../Components/dashboard"));
const Layout = lazy(() => import("../Layout"));
const Error404 = lazy(() => import("../Components/Error404"));
const Chat = lazy(() => import("../Components/chat/messenger"));
//const Landing = lazy(() => import("../Pages/Landing"));

const Routers = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<FullPageLoader />}>
        <Routes>
          <Route path={`/`} element={<Dashboard />} />
          <Route path={`/dashboard`} element={<NavigateTo to="/" />} />
          <Route path={`/chat`} element={<RedirectRoute><Chat /></RedirectRoute>} />
          { /* Here is an example how to hide the chat from the anonymous 
            <Route path={`/chat/:personaId/:chatId`} element={<RedirectRoute><Chat /></RedirectRoute>} />
          */}
          <Route path={`/chat/:personaId/:chatId`} element={<Chat />} />

          {AuthRoutes.map((item, index) => (
            <Route key={index} path={item.path} element={item.element} />
          ))}
          <Route element={<Layout />}>
            <Route path={"*"} element={<Error404 />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routers;
