import { createSlice } from "@reduxjs/toolkit";
import { ChatSliceType } from "../../../Types/MessengerType";

const initialState: ChatSliceType = {
  allMembers: [],
  chatMembers: [],
  chatsLoaded: false,
  chats: [],
  chatMessages: [],
  currentUser: null,
  selectedUser: null,
  isTyping: false,
  activeCallUser: null,
};

const ChatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setAllMembers: (state, action) => {
      state.allMembers = action.payload;
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    setChats: (state, action) => {
      state.chats = action.payload;
    },
    setChatsLoaded: (state, action) => {
      state.chatsLoaded = action.payload;
    },
    setChatMessages: (state, action) => {
      state.chatMessages = action.payload;
    },
    fetchChatMemberAsync: (state) => {
      if (state.allMembers.length > 0) {
        state.currentUser = state.allMembers[0];
        state.chatMembers = state.allMembers;
      }
    },
    changeChat: (state, action) => {
      let newUser = state.allMembers.find((x: { id: number }) => x.id === action.payload);
      state.selectedUser = newUser;
    },
    replyByUserAsync: (state, action) => {
      console.log("replyByUserAsync")
      console.log(action.payload);
      /*
      let chat = state.chats.find((x: ChatsTypes) => x.users.includes(action.payload.currentUserId) && x.users.includes(action.payload.selectedUserId));
      if (chat) {
        chat.messages.push({
          sender: action.payload.selectedUserId,
          time: action.payload.time,
          class: "sent",
          text: action.payload.replyMessage,
          status: true,
        });
        chat.lastMessageTime = action.payload.time;
        let chats_data = state.chats.filter((x: { id?: number }) => x.id !== chat?.id);
        chats_data.splice(0, 0, chat);
      }
      */
    },
    typingMessage: (state, action) => {
      state.isTyping = action.payload;
    },
    setActiveCallUser: (state, action) => {
      state.activeCallUser = action.payload;
    },
  },
});

export const { 
  fetchChatMemberAsync, 
  changeChat, 
  setAllMembers, 
  setSelectedUser, 
  setChats, 
  setChatsLoaded,
  setChatMessages,
  replyByUserAsync, 
  typingMessage, 
  setActiveCallUser 
} = ChatSlice.actions;

export default ChatSlice.reducer;
