import React, { useEffect } from "react";
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from "react-redux";
import { getAuth, signInAnonymously } from "firebase/auth";
import { setAppId, setUser, setUserAuth } from "../../store/Reducers/user";
import { memberCreate, memberGet, memberUpdate, memberSubscribeById } from "../../api/member";
import { setChats, setChatsLoaded } from "../../store/Reducers/Messenger/Chat";
import { memberChatsGetAll, memberChatsSubscribe } from "../../api/member-chat";
import { presetPersonasGetAll } from "../../api/preset-personas";
import { setPersonas } from "../../store/Reducers/personas";
import { setChatWallpaper, setColorPicker } from "../../store/Reducers/TemplateCustomizerReducers";
import { authUserCreate, authUserGet, authUserUpdate } from "../../api/auth-user";
import { firebaseObserver } from "../../api/firebase";
import { anonymousChatsSave } from "../../api/anonymous";

export default function AuthenticationObserver({ children }) {
  const {auth, appId} = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const handleUserData = (userObject) => { 
    if(userObject && userObject.exists) {
      dispatch(setUser(userObject.metadata))

      if(auth?.photoURL && auth.photoURL !== userObject.metadata.photoURL) {
        memberUpdate(appId, { photoURL: auth.photoURL })
      }

      if(auth?.email && auth.email !== userObject.metadata.email) {
        memberUpdate(appId, { email: auth.email })
      }

      if(auth?.displayName && auth.displayName !== userObject.metadata.displayName) {
        memberUpdate(appId, { displayName: auth.displayName })
      }

      const customizer = userObject.metadata.customizer
      if(customizer) {
        if(customizer.colorPrimary) {
          dispatch(setColorPicker(customizer.colorPrimary))
        }

        if(customizer.chatWallpaper) {
          dispatch(setChatWallpaper(customizer.chatWallpaper))
        }
      }
    }
  }

  useEffect(() => {
    presetPersonasGetAll().then((data) => {
      dispatch(setPersonas(data))
    })
  }, [])

  useEffect(() => {
    firebaseObserver.subscribe('authStateChanged', async change => {
      console.log('Auth state changed', change)
      console.log('Previous user', auth, auth?.isAnonymous)
      console.log('Current user', change, !change?.isAnonymous)
    
      if(auth?.isAnonymous && !change?.isAnonymous) {
        console.log('Case to connect chats')
        console.log('If user just created, then check old settings and import them too')
          setTimeout(async () => {
            const response = await anonymousChatsSave(auth.uid)
            console.log('Chats saved', response)
          }, 1000)
        }
    
      if(!change) {
        signInAnonymously(getAuth())
          .then(async (change) => {
            // Signed in..
            // Create a new auth-user object
            console.log(change)
            const newAppId = uuid().toUpperCase()
            try {
              await authUserCreate(change.user.uid, {appId: newAppId})
            } catch (error) {
            }

            dispatch(setAppId(newAppId))
            dispatch(setUserAuth(change?.user || null))
          })
          .catch((error) => {
            console.log('Anonymous auth error', error)
          });
      } else {
        dispatch(setUserAuth(change?.auth.currentUser || null))
      }
    
    });
    
    return () => { 
      firebaseObserver.unsubscribe('authStateChanged'); 
    }
  }, [auth])

  useEffect(() => {
    if(auth && auth.uid) {
      authUserGet(auth.uid).then(userObject => {
        if(userObject?.metadata?.appId) {
          dispatch(setAppId(userObject.metadata.appId))
        } else {
          const newAppId = uuid().toUpperCase()
          dispatch(setAppId(newAppId))
          authUserUpdate(auth.uid, {appId: newAppId})
        }
      }).catch(async error => {
        console.log('authUserGet error', error)
      })
    }
  }, [auth])

  useEffect(() => {
    let unsubscribe = () => {}

    if(appId) {
      memberGet(appId).then(userObject => {
        handleUserData(userObject)
        unsubscribe = memberSubscribeById(appId, data => {
          handleUserData(data)
        })
      }).catch(async error => {
        const userData = {
          uid: auth.uid,
          photoURL: auth.photoURL,
          email: auth.email,
          displayName: auth.displayName,
        }

        memberCreate(appId, userData).then(() => {
          unsubscribe = memberSubscribeById(appId, data => {
            const userObject = data[0]
            handleUserData(userObject)
          })
        })
      })
    }

    return unsubscribe

    }, [appId])
    
  useEffect(() => {
    if(!appId) {
      return () => {}
    }

    memberChatsGetAll(appId).then(async data => {
      await dispatch(setChats(data.map((chat) => {
        return {
          id: chat.id,
          exists: true,
          ...chat.metadata,
          messages: []
        }
      })))
      await dispatch(setChatsLoaded(true));
    })

    return memberChatsSubscribe(appId, async (data) => {
      await dispatch(setChats(data.map((chat) => {
        return {
          id: chat.id,
          exists: true,
          ...chat.metadata,
          messages: []
        }
      })))
      await dispatch(setChatsLoaded(true));
    })
  }, [appId]);

  return children;
}