import { lazy } from "react";
import { ToastContainer } from "react-toastify";
import AuthenticationObserver from "./Components/auth/observer";
import Routers from "./Routers/Routers";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppSelector } from "./store/Hooks";
const Mixpanel = lazy(() => import('./api/mixpanel.jsx'));

const App = () => {
  const color = useSelector((state) => state.templateCustomizer.colorPicker);
  const { appId } = useAppSelector((state) => state.user);
  const [triggerMixpanel, setTriggerMixpanel] = useState(false);

  useEffect(() => {
    // initialize color style
    const activeColor = color.find((item) => item.active);
    document.documentElement.className = activeColor?.color;
  }, [color]);

  useEffect(() => {
    if(!appId) {
      return () => {}
    }

    setTriggerMixpanel(true);
  }, [appId])

  return (
    <AuthenticationObserver>
      {triggerMixpanel &&
        <Mixpanel
          identify={appId}
          success={() => setTriggerMixpanel(false)}
        />
      }
      <Routers />
      <ToastContainer />
    </AuthenticationObserver>
  );
};

export default App;
